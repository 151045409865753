import UnderConstruction from './components/UnderConstruction/UnderConstruction';
import s from './App.module.sass';

function App() {
  return (
    <div className={s.app}>
      <UnderConstruction />
    </div>
  );
}

export default App;
