import React, { Component } from 'react';
import s from './UnderConstruction.module.sass';

class UnderConstruction extends Component {


	render() {
		return (
			<div className={s.container}>
				<div className={s.tape}/>
					<div className={s.middle}>
						<h1>Under construction</h1>
						<img
							src='/images/roy.jpg'
						/>
						<span>Stay tuned!</span>
					</div>
				<div className={`${s.tape} ${s.bottomTape}`} />
			</div>
		);
	}

}



export default UnderConstruction;
